<template>
  <div>
    <v-avatar color="grey" :size="size" class="my-4">
      <img v-if="photoURL" :src="photoURL" />
      <span v-else class="white--text text-uppercase">
        {{ nameInitials }}
      </span>
    </v-avatar>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({
        displayName: '',
        photoURL: '',
      }),
    },
    size: {
      type: Number,
      default: 80,
    },
  },

  computed: {
    photoURL() {
      return this.user?.photoURL;
    },
    nameInitials() {
      return this.user?.displayName
        ?.split(' ')
        .reduce((acum, name) => (acum += name[0]), '');
    },
  },
};
</script>
